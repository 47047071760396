import http from '@/utils/request'

// 获取banner
export const getbannerList = (data) => http.post("/system/sysDictData/getSysDictDataByByDictType", data)
// 咨询列表
export const getnewsList = (data) => http.post("/info/article/queryList", data)
// 合作伙伴
export const getpartnerList = (data) => http.post("/system/sysDictData/getSysDictDataByByDictType", data)
// 登录
export const logIn = (data) => http.post("/uauth/auth/getToken", data)
// 发送验证码
export const getsendSmsCode = (data) => http.postquery("/third/third/sendSmsCode", data)
export const getsendSmsCodes = (data) => http.postquery("/system/sysUser/sendSmsCode", data)
// 注册
export const register = (data) => http.post("/system/sysUser/userRegister", data)
// 重置密码
export const userForgetPassword = (data) => http.post("/system/sysUser/userForgetPassword", data)
// 新疆市场列表
export const getxinjList = (data) => http.post("/info/infoMarket/selectMarketInfoList", data)
// 行业简评
export const hangyeList = (data) => http.post("info/article/queryList", data)
// 公告
export const biddingList = (data) => http.post("/system/sysMessage/page", data)
// 公告详情
export const biddingdetail = (data) => http.post("/info/sysMessageDetail", data)
// export const biddingdetail = (data) => http.post("/system/sysMessage/detail", data)
// 交易总额
export const gmvdetail = (data) => http.post("/trade/tradeOrder/total", data)
// 挂牌销售
export const nominal = (data) => http.post("/trade/tradeGoods/page", data)
// 权限查询
export const getqxshuju = (data) => http.get("/system/authCompany/detailAuthCompanyByEnterpriseId", data)
// 商品下架
export const tradeGoodsClose = (data) => http.get("/trade/tradeGoods/close/" + data)
// 我发布的服务列表接口
export const serviceGood = (data) => http.post("/trade/serviceGood/page", data)
// 下架服务服务商品
export const onShelf = (data) => http.post("/trade/serviceGood/onShelf", data)
// 删除服务商品
export const serviceGooddelete = (data) => http.post("/trade/serviceGood/delete", data)
// 服务商品详情
export const serviceGooddetail = (data) => http.postquery("/trade/serviceGood/detail", data)
// 服务订单列表
export const listServiceOrder = (data) => http.post("/trade/serviceOrder/listServiceOrder", data)
// 服务订单详情
export const detailServiceOrder = (id) => http.get("/trade/serviceOrder/detailServiceOrder/" + id)
// 订单流转 
export const dealServiceOrder = (data) => http.post("/trade/serviceOrder/dealServiceOrder", data)
// 上传车辆
export const uploadTransportCars = (data) => http.post("/trade/serviceOrder/uploadTransportCars", data)
// 我关注的服务商品列表
export const serviceGoodCollection = (data) => http.post("/trade/serviceGoodCollection/page", data)
// 上下架商品
export const collection = (data) => http.post("/trade/serviceGoodCollection/collection", data)
// 金融订单下单
export const placeFinanceOrder = (data) => http.post("/trade/serviceOrder/placeFinanceOrder", data)
// 金融订单详情
export const applyQuota = (data) => http.postquery("/trade/applyQuota/detail", data)
// 金融订单详情
export const applymenoy = (data) => http.post("/trade/serviceOrder/payServerOrder", data)
// 借款详情
export const borrowFundsRecord = (data) => http.postquery("/trade/borrowFundsRecord/detail", data)
// 借款申请
export const addborrowFundsRecord = (data) => http.post("/trade/borrowFundsRecord/apply", data)
//查询车辆列表
export const listTransportCars = (id) => http.post("/trade/serviceOrder/pageTransportCars/", id)
// 发起运输
export const placeTransportOrder = (data) => http.post("/trade/serviceOrder/placeTransportOrder", data)
// 查询用户余额
export const queryyue = () => http.get("/finance/financeWallet/getNowBalances")
// 查询是否设置密码
// export const havePwd = (data) => http.getquery("",data)
export const havePwd = (data) => http.request({
  method: 'get',
  url: '/finance/financeWallet/havePwd',
  params: data,
})
// setPwd
export const setPwd = (data) => http.post("/finance/financeWallet/setPwd", data)
export const updatePwd = (data) => http.request({
  method: 'post',
  url: '/finance/financeWallet/updatePwd',
  data,
})
export const ifSetManagePwd = (data) => http.post("/system/sysUser/ifSetManagePwd", data)
export const financeWallet = (data) => http.getquery("/finance/financeWallet/havePwd", data)
//修改密码
export const userUpdatePassword = (data) => http.post('/system/sysUser/userUpdatePassword',data)
// 文字轮播
export const sysRotateTextList = (data) => http.post('/system/sysRotateText/list',data)
// 
export const listContract = (data) => http.get("/trade/cyReplenish/listContract?bizId="+ data)
// 
export const listBankAccount = (data) => http.get("/trade/cyReplenish/listBankAccount?orgCode="+ data)
