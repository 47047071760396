import {
  beforhandle,
  nobeforhandle
} from '@/utils/authority'
import store from '@/store'
export default {
  'base': async function (to, from, next) {
    let jurisdiction = {
      '/userIndex': ['personalCenter'],
      '/manage': ['personalCenter:bank'], //我的银行卡,
      '/myAssets': ['personalCenter:myAssets'], //我得资产
      '/jiaoyi': ['personalCenter:transactionRecord'], //交易记录
      '/finance': ['personalCenter:accountSecurity'], //账户安全
      '/myPublished': ['personalCenter:publishedServices'], //我发布的服务
      '/financeTax': ['personalCenter:taxOrder'], //财税服务订单
      '/banking': ['personalCenter:financialOrder'], //金融服务订单
      '/logistics': ['personalCenter:logisticsOrder'], //物流服务订单
      '/warehouse': ['personalCenter:storageOrder'], //仓储服务订单
      '/science': ['personalCenter:scienceOrder'], //科技服务订单
      '/released': ['personalCenter:auctionSale:publish'], //竞价销售
      '/participation': ['personalCenter:auctionSale:partake'], //竞价销售
      '/purchase': ['personalCenter:biddingPurchase:publish'], //竞价采购
      '/take': ['personalCenter:biddingPurchase:partake'], //竞价采购
      '/purchasehangtag': ['personalCenter:ListingPurchase:publish'], //挂牌采购
      '/markethangtag': ['personalCenter:listingSales:publish'], //挂牌销售
      '/order': ['personalCenter:orderInfo'], //订单信息
      '/personnelStructure': ['personalCenter:personnelStructure'], //人员架构
      '/record': ['personalCenter:operationRecord'], //操作记录
      '/guanzhu': ['personalCenter:goodsCollect'], //收藏的商品
      '/qianzhang': ['personalCenter:mySignature'], //我的签章
      '/usmyPublished': ['personalCenter:focusServiceGoods'], //我关注的服务商品
      '/usfinanceTax': ['personalCenter:ptaxOrder'], //财税服务订单
      '/uswarehouse': ['personalCenter:pstorageOrder'], //仓储服务订单
      '/usscience': ['personalCenter:pscienceOrder'], //科技服务订单
      '/contract': ['personalCenter:contractManage'], //合同管理
      '/usbanking': ['personalCenter:pfinancialOrder'], //我关注的服务：金融
      //服务大厅
      '/usmyPublished': ['personalCenter:focusServiceGoods'], //我发布的服务
      '/usfinanceTax': ['personalCenter:ptaxOrder'], //财税服务
      '/usbanking': ['personalCenter:pfinancialOrder'], //金融服务
      '/uslogistics': ['personalCenter:plogisticsOrder'], //物流服务
      '/uswarehouse': ['personalCenter:pstorageOrder'], //仓储服务
      '/usscience': ['personalCenter:pscienceOrder'], //科技服务
      '/customer' : ['personalCenter:customerRelationship:view'],//客户关系管理
    }
    // let jurisdiction = {
    //   '/userIndex': ['all'],
    //   '/information': ['all'],
    //   '/manage': ['all'],//我的银行卡,
    //   '/myAssets':'all',//我得资产
    //   '/jiaoyi':'all',//交易记录
    //   '/finance':'all',//账户安全
    //   '/myPublished':'all',//我发布的服务
    //   '/financeTax':'all',//财税服务订单
    //   '/banking':'all',//金融服务订单
    //   '/logistics':'all',//物流服务订单
    //   '/warehouse':'all',//仓储服务订单
    //   '/science':'all',//科技服务订单
    //   '/released':'all',//竞价销售
    //   '/participation':'all',//竞价销售
    //   '/purchase':'all',//竞价采购
    //   '/take':'all',//竞价采购
    //   '/purchasehangtag':'all',//挂牌采购
    //   '/markethangtag':'all',//挂牌销售
    //   '/order':'all',//订单信息
    //   '/personnelStructure':'all',//人员架构
    //   '/record':'all',//操作记录
    //   '/guanzhu':'all',//收藏的商品
    //   '/qianzhang':'all',//我的签章
    //   '/usmyPublished':'all',//我关注的服务商品
    //   '/usfinanceTax':'all',//财税服务订单
    //   '/uswarehouse':'all',//仓储服务订单
    //   '/usscience':'all',//科技服务订单
    //   '/contract':'all',//合同管理
    // }
    let nologin = {
      // '/mall': ['listingPurchase', 'listingSales', 'auctionSale', 'biddingPurchase'],
      '/financial': ['serviceHall'],
      // '/aboutus': ['aboutUs'],
      // '/goodsDetail': ['listingPurchase:detail', 'listingSales:collect', 'auctionSale:detail', 'biddingPurchase:detail'],
      'financialDetails': ['serviceHall:detail'],
    }
    // let nologin = {
    //   '/mall': ['shinglepurchase','shinglemarket','biddingmarket','biddingpurchase'],
    //   '/financial': ['financial','logistics'],
    //   '/aboutus': ['aboutus'],
    //   '/announcement': ['biddingnotice','winnotice'],
    // }
    
    if (jurisdiction[to.path]) {
      beforhandle(jurisdiction[to.path]) ? next() : next(false)
      console.log('ee');
    } else if (nologin[to.path]) {
      // nobeforhandle(nologin[to.path]) ? next() : next(false)
      // if (to.fullPath == '/mall?selectTradeType=1&selectOrderType=2') {
      //  return beforhandle("listingPurchase") ? next() : next(false)
      // } else if (to.fullPath == '/mall?selectTradeType=1&selectOrderType=1') {
      //   return beforhandle("listingSales") ? next() : next(false)
      // } else if (to.fullPath == '/mall?selectTradeType=2&selectOrderType=1') {
      //   return beforhandle("auctionSale") ? next() : next(false)
      // } else if (to.fullPath == '/mall?selectTradeType=2&selectOrderType=2') {
      //   return beforhandle("biddingPurchase") ? next() : next(false)
      // }
      // if (to.path == '/goodsDetail') {
      //   if (to.query.selectOrderType == '2' && to.query.selectTradeType == '1') {
      //     beforhandle("listingPurchase:detail") ? next() : next(false)
      //   } else if (to.query.selectOrderType == '1' && to.query.selectTradeType == '1') {
      //     beforhandle("listingSales:detail") ? next() : next(false)
      //   } else if (to.query.selectOrderType == '1' && to.query.selectTradeType == '2') {
      //     beforhandle("auctionSale:detail") ? next() : next(false)
      //   } else if (to.query.selectOrderType == '2' && to.query.selectTradeType == '2') {
      //     beforhandle("biddingPurchase:detail") ? next() : next(false)
      //   }
      // }
       nobeforhandle(nologin[to.path]) ? next() : next(false)
    } else {
      next()
    }
  },
  clearCancelToken: async function (to, from, next) {
    store.commit('user/clearCancelToken')
    next()
  }
}