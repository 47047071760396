export default promise => promise.then(ret => [ret, null]).catch(err => [null, err])
/**
 * const [err, ret] = to(testApi())
 * if (err) {
 *  console.log(err)
 *  return
 * }
 * if (ret.code == 0) {
 *  ....
 * }
 */