import { reqGetUser } from "@/api/user.js";
import * as types from "../action-types";
import Vue from "vue";
export default {
  state: {
    userInfo: null,
    permission: null,
    token: "",
    cancelTokenArr: [],
    massage: "",
  },
  mutations: {
    [types.GET_USERINFO](state, payload) {
      state.userInfo = payload;
    },
    permission(state, payload) {
      state.permission = payload;
    },
    addCancelToken(state, cancel) {
      cancel && state.cancelTokenArr.push(cancel);
    },
    // 取消所有请求
    clearCancelToken(state) {
      state.cancelTokenArr.forEach((cancel) => {
        cancel && cancel();
      });
      state.cancelTokenArr = [];
    },
    changeMassage(state, payload) {
      console.log(payload,'payload')
      state.massage = payload;
    },
  },
  actions: {
    async [types.GET_USERINFO]({ commit }) {
      // let {data} = await reqGetUser()
      // Vue.ls.set(types.USER_INFO, data)
      // commit(types.GET_USERINFO, data)
    },
  },
};
