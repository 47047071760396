import Vue from 'vue'
import VueRouter from 'vue-router'
import hooks from './hooks'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = []
const files = require.context('./routes/', false, /\.router.js$/);
files.keys().forEach(key => {
  routes.push(...files(key).default)
})
const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})



Object.values(hooks).forEach(hook => {
  router.beforeEach(hook.bind(router))
})

export default router;