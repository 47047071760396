export default {
  upLoadUrl: `/third/third/ossUploadFile`,
  getFileUrl: `third/third/ossGetFile/`, // {fileName}
  // 获取企业认证详情
  getCompanyAuthDetail: "/system/authCompany/detailAuthCompanyByEnterpriseId",
  // 企业认证/修改接口
  addAuthCompany: "/system/authCompany/addAuthCompany",
  updateConpanyBankAccount: "/system/authCompany/updateConpanyBankAccount",
  // 获取用户企业信息
  getCompanyDetail: "/system/sysUser/getEnterpriseById",
  // 添加用户
  addUser: "/system/sysUser/addUser",
  // 获取用户列表
  getList: "/system/sysUser/userList",
  // 用户删除/启用
  enableOrDeleteUser: "/system/sysUser/enableOrDeleteUser",
  // 查询用户信息
  getUserDetail: "/system/sysUser/getUserByUserId",
  // 编辑用户
  editUser: "/system/sysUser/editUser",
  // 用户发送短信设置
  financeChangeNotice: "/finance/financeChangeNotice/set",
  // 查询通知设置状态
  getNoticeStatus: "/finance/financeChangeNotice/getNoticeStatus",
  // 查询用户菜单权限
  getUserMenuTree: "/system/sysUser/getUserMenuTreeByUserId",
  // 编辑用户权限
  editUserMenuTree: "/system/sysUser/editUserMenuTreeByUserId",
  // public部分
  // 获取分类
  getDictType: "/system/sysDictData/getSysDictDataTreeByDictType",
  // 获取列表
  getGoodsList: "/trade/tradeGoods/page",
  // 新增商品
  addGoods: "/trade/tradeGoods/listing/add",
  // 新增竞价商品
  addBiddingGoods: "/trade/tradeGoods/bidding/add",
  //新增拼团
  addGroupGoods: "/trade/tradeGroup/addGroupGoods",
  // 关注
  follow: "/trade/tradeGoods/follow",
  // 下单
  createOrder: "/trade/tradeGoods/listing/createOrder",
  // 立即报价
  biddingOffer: "/trade/tradeOffer/bidding/offer",
  // 发送验证码
  sendCode: "/third/third/sendSmsCode",
  // 支付保证金
  payBond: "/trade/tradeGoods/bidding/bond",
  // 查询是否支付保证金
  isPayBond: "/trade/tradeGoods/bondInfo/",
  isPayBonds: "/finance/financeWallet/havePwd",
  // 利润计算器
  profitCalculation: "/trade/calculation/newProfitCalculation",
  // 检测交易密码
  checkPassword: "/system/sysUser/checkPassword",
  // getTradeOffer
  getTradeOffer: "/trade/tradeOffer/page",
  //银行模糊搜索
  getSysDictDataByByDictType: "/system/sysDictData/getSysDictDataByByDictType",
  //企业管理账号
  getEnterpriseByEnterpriseId: "/system/sysUser/getEnterpriseByEnterpriseId",
  //修改企业管理账号
  updateEnterpriseAccount: "/system/sysUser/updateEnterpriseAccount",
  //用户开会
  registerAndAddWallet: "/system/authCompany/registerAndAddWallet",
  //注销账户
  cancelBankAccount: "/finance/financeCapitalAccount/cancelBankAccount",
};
