import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueBus from '@/utils/vueBus';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/iconfont/iconfont.css';
import Storage from 'vue-ls'
import { Message } from 'element-ui';
import to from '@/utils/awaitTo.js'
import keyworder from '@/components/toaplay'
import { reqGetToekn, reqkey } from "@/api/public";
import init from '@/utils/init'
Vue.component("keyworder", keyworder)
import {
  hasPermission,
  jurisdiction,
  beforhandle,
  nobeforhandle
} from './utils/authority'
Vue.prototype.hasPer = hasPermission
Vue.prototype.jurisdiction = jurisdiction
Vue.prototype.beforhandle = beforhandle
Vue.prototype.nobeforhandle = nobeforhandle

Vue.prototype.$to = to
Vue.prototype.$YTmsg = (msg, type) => {
  let obj = {
    message: msg,
    type: type,
    customClass: type + "_diolog",
    offset: 100
  };
  switch (type) {
    case 'info':
      Message.info(obj);
      break;
    case 'success':
      Message.success(obj);
      break;
    case 'error':
      Message.error(obj);
      break;
    case 'warning':
      Message.warning(obj);
      break;
    default:
      Message.info(obj);
      break;
  }
}
Vue.prototype.$verify = {
  //  手机号校验
  phone: (value) => {
    // let myreg = /^[1][3,5,7,8][0-9]{9}$/;
    let myreg = /^[1][0-9]{10}$/;
    return myreg.test(value);
  },
  //  验证码校验
  code: (value) => {
    let myreg = /^\d{6}$/;
    return myreg.test(value);
  },
  //  用户名校验
  userName: (value) => {
    let myreg = /^[\u4E00-\u9FA5A-Za-z0-9]{4,14}$/;
    return myreg.test(value);
  },
  //  密码校验
  password: (value) => {
    let myreg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
    // let myreg = /^[\d\w]{6,20}$/;
    return myreg.test(value);
  },
  //  邮箱校验
  email: (value) => {
    let myreg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    return myreg.test(value);
  },
  //  qq号码校验
  qq: (value) => {
    let myreg = /^[1-9][0-9]{4,15}$/gim;
    return myreg.test(value);
  },
  //  为空校验
  empty: (value) => {
    let myreg = /\S/;
    return myreg.test(value);
  }
}
// 引入Base64加密
let Base64 = require('js-base64').Base64
const { storageOptions } = require('@/config/index')
Vue.config.productionTip = false
Vue.use(vueBus);
Vue.use(ElementUI)
Vue.use(Storage, storageOptions)
// 保证先拿到加密的key / 再获取到 token后 vue生成实例
init().then(() => {
  new Vue({
    router,
    store,
    Base64,
    render: h => h(App),
  }).$mount('#app')
})
