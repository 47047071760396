import url from './url/public_url.js'
import http from '@/utils/request'
import about from './url/about_url.js'
// 完善合同
export const getOnlineContractInfo = (id) => http.get(about.getOnlineContractInfo+`?orderId=${id}`)
export const updateOnlineContractInfo = (data) => http.post(about.updateOnlineContractInfo, data)
// 完善物流竞价合同
export const getOnlineContractTransportInfo = (id) => http.get(about.getOnlineContractTransportInfo+`?orderId=${id}`)
export const updateOnlineContractTransportInfo = (data) => http.post(about.updateOnlineContractTransportInfo, data)
// 获取token
export const reqGetToekn = (data) => http.post(url.getToken, data)
// 获取加密key
export const reqkey = () => http.get('/system/encrypt/getSecretKey')
// 获取公司介绍
export const getAboutList = (data) => http.post(about.getAboutList, data)
// 获取系统消息列表
export const getMessageList = (data) => http.post(about.getMessage, data)
// 标记所有消息为已读
export const updateAll = (data) => http.post(about.updateAll, data)
// 竞价销售-我发布的，列表
export const tradeGoods = (data) => http.post(about.tradeGoods, data)
// 竞价销售-我发布的，详情
export const tradeDtetails = (goodsId) => http.get(about.tradeDtetails + `${goodsId}`)
// 竞价销售-我发布的，详情
export const setclose = (goodsId) => http.get(about.setclose + `${goodsId}`)
// 竞价销售-我发布的，列表
export const tradeOffer = (data) => http.post(about.tradeOffer, data)
// 订单信息列表
export const tradeOrder = (data) => http.post(about.tradeOrder, data)
// 订单信息详情
export const orderDetail = (data) => http.post(about.orderDetail, data)
// 文件上传
export const ossUploadFile = (data) => http.post(about.ossUploadFile, data)
// 文件上传2
export const ossUploadFiletwo = (data) => http.post(about.ossUploadFiletwo, data)
// 文件上传
export const ossUploadFiletw = (data) => http.post(about.ossUploadFiletw, data)
// 合同保存地址
export const saveContractUrl = (data) => http.post(about.saveContractUrl, data)
// 订单开票列表
export const invoicepage = (data) => http.post(about.invoicepage, data)
// 订单支付列表
export const paymentpage = (data) => http.post(about.paymentpage, data)
// 订单运输列表
export const trafficpage = (data) => http.post(about.trafficpage, data)
// 订单签收记录
export const signpage = (data) => http.post(about.signpage, data)
//发起运输
export const addtrafficpage = (data) => http.post(about.addtrafficpage, data)
//发起开票
export const addinvoice = (data) => http.post(about.addinvoice, data)
//获取合同
export const detailByOrder = (goodsId) => http.get(about.detailByOrder + `${goodsId}`)
//确认合同无误
export const confirm = (data) => http.post(about.confirm, data)
//添加线下支付
export const addpayment = (data) => http.post(about.addpayment, data)
//确认金额已到账
export const addconfirm = (data) => http.post(about.addconfirm, data)
//订单签收信息增加
export const addsign = (data) => http.post(about.addsign, data)
// 订单结算详情
export const getSignDetail = (data) => http.post(about.getSignDetail,data)
// 取消结算
export const cancelSettle = (data) => http.post(about.cancelSettle,data)
// 订单结算数据导出
export const exportSign = (data) => http.postquerys(about.exportSign,data)
// 退还预付款
export const returnPrepayment = (data) => http.post(about.returnPrepayment,data)
//订单满意或不满意
export const finish = (data) => http.post(about.finish, data)
//
export const updateVoucher = (data) => http.post(about.updateVoucher, data)
//合同模板列表
export const listTemplate = (data) => http.post(about.listTemplate, data)
//查询服务
export const listByDictType = (data) => http.post(about.listByDictType,`dictType=${data}`)
//服务大厅列表
export const serviceGoodpage = (data) => http.post(about.serviceGoodpage, data)
//成为服务商
export const serviceProvider = (data) => http.post(about.serviceProvider, data)
//申请服务
export const serviceGood = (data) => http.post(about.serviceGood, data)
//查看服务商状态
export const getServiceProviderStatus = (data) => http.post(about.getServiceProviderStatus, data)
//查看详情
export const serviceGooddetail = (data) => http.post(about.serviceGooddetail+`${data}`)
// 邀约商品详情
export const getInvoiceDetail = (data) => http.post(about.getInvoiceDetail, data)
//关注或取消关注
export const collection = (data) => http.post(about.collection, data)
//其他订单下单
export const placeOtherOrder = (data) => http.post(about.placeOtherOrder, data)
//开票确认
export const confirmInvoice = (data) => http.post(about.confirmInvoice, data)
//我收藏的运输列表
export const serviceGoodCollection = (data) => http.post(about.serviceGoodCollection, data)
//我收藏的运输列表
export const setedit = (data) => http.post(about.setedit, data)
//发起服务
export const placeTransportOrderFromTrade = (data) => http.post(about.placeTransportOrderFromTrade, data)
//我收藏的运输列表
export const listTransportCarsByParam = (data) => http.post(about.listTransportCarsByParam, data)
//确认发货
export const confirmDeliver = (data) => http.post(about.confirmDeliver, data)
//确认到货
export const confirmReceive = (data) => http.post(about.confirmReceive, data)
//买方确认收货
export const confirmSettle = (data) => http.post(about.confirmSettle, data)
//最新开票信息
export const getNewestInvoice = (data) => http.post(about.getNewestInvoice, data)
//最新开票信息
export const payServerOrder = (data) => http.post(about.payServerOrder, data)
//最新开票信息
export const financeBankCard = (data) => http.post(about.financeBankCardadd, data)
//查看银行卡
export const bankCardList = (data) => http.post(about.bankCardList, data)
//提现申请
export const withdraw = (data) => http.post(about.withdraw, data)
//获取当前帐号余额
export const getNowBalances = (data) => http.get(about.getNowBalances, data)
//查询用户资金账户列表
export const financeCapitalAccount = (data) => http.get(about.financeCapitalAccount+`${data}`)
//解绑银行卡
export const financeBankCards = (data) => http.post(about.financeBankCard,data)
//交易订单支付

export const payTradeOrder = (data) => http.post(about.payTradeOrder,data)
//在线签章
export const updateSignatureStatus = (data) => http.post(about.updateSignatureStatus,data)
//清理已读
export const deleteAll = (data) => http.post(about.deleteAll,data)
//拼团交易详情
export const groupDtetails = (data) => http.get(`/trade/tradeGroup/getGroupDetail?goodsId=${data}`)
export const uploadContractAnnex = (data) => http.post('/trade/tradeInvite/uploadContractAnnex', data)


// 物流详情
export const tGroupDtetails = (data) => http.post(`/trade/tradeTransportBidding/getTbGoodsDetail?goodsId=${data}`)

export const listAdjustPrice = (data) => http.post(about.listAdjustPrice,data)
// 添加调价信息
export const addAdjustPrice = (data) => http.post(about.addAdjustPrice,data)
// 查看订单价格
export const getOrderPrice = (data) => http.post(about.getOrderPrice,data)
// 同意/拒绝调价信息
export const dealAdjustPrice = (data) => http.post(about.dealAdjustPrice,data)



// 预付款余额查询
export const getPrepaymentBalance = (data) => http.post('/trade/tradeOrder/sign/getPrepaymentBalance',data) 


// 数字计算
export const amountCalculation = (data) => http.post('/trade/tradeOffer/amountCalculation',data)
// 能源数据修改记录
export const modifyRecordList = (data) => http.post('/system/dataReport/modifyRecordList', data)
// 能源数据修改审核
export const modifyAudit = (data) => http.post('/system/dataReport/modifyAudit', data)
