
exports.isProxy = false // 是否开启代理
const baseUrlObject = {
  'prod': '192.168.0.26', // 生产环境 勿改
  'pre': 'https://api.xjcec.com/',//pre环境
  'test': 'https://testapi.xjcec.com/', // 测试环境  勿改
  'dev': 'https://testapi.xjcec.com/'  // 本地开发环境  
}
const envNameList = () => Object.keys(baseUrlObject)
// 基础url
let baseUrl = baseUrlObject[process.env.VUE_APP_EDITION || envNameList()[process.argv[4] - 1]]
exports.proxyConfig = {
  "/api": {
    target: baseUrl || '/api',
    changeOrigin: true,
    ws: true
  }
}
