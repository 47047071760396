import {
  Notification,
  MessageBox,
  Message
} from 'element-ui'

import router from '@/router'
// 权限检查方法
export function hasPermission(data) {
  const buttonperms = localStorage.getItem('buttenpremissions')
  if (typeof data =='object' && data.constructor == Array) {
    return data.some(item => {
      return buttonperms.indexOf(item) > -1
    })
  } else {
    return buttonperms.indexOf(data) > -1
  }
}
export function jurisdiction(no) {
  if (!no) {
    // debugger
    Notification({
      title: '消息提示',
      type: 'error',
      message: '您没有此项权限！',
      position: 'bottom-right'
    })
  }
}
export function nobeforhandle(data) {
  let foo = true
  let Storage = window.localStorage.getItem("massage")
  if (Storage == '') {
    //all 代表不做权限限制
    if (data == 'all' || hasPermission(data)) {
    } else {
      foo = false
      jurisdiction()
    }
  }
  return foo
}
export function beforhandle(data, no) {
  // console.log(data);
  let foo = false
  let Storage = window.localStorage.getItem("massage")
  // console.log(Storage, 'Storage');
  if (Storage == '') {
    //all 代表不做权限限制
    if (data == 'all' || hasPermission(data)) {
      foo = true
    } else {
      jurisdiction(no)
    }
  } else if (Storage == "未登录请前往登录") {
    Message.error(Storage)
  } else if (Storage == "您的企业信息正在审核中请耐心等待") {
    if (JSON.stringify(data).indexOf('userIndex') != -1) {
      foo = true
    } else {
      MessageBox.alert(Storage, '', {
        confirmButtonText: '已知悉',
      });
    }
  } else if (Storage == "请前往认证后操作") {
    if (JSON.stringify(data).indexOf('userIndex') != -1) {
      foo = true
    } else {
      MessageBox.confirm(Storage, '提示', {
        confirmButtonText: '交易会员认证',
        cancelButtonText: '中长期会员认证',
        type: 'warning',
        customClass: 'hy-confirm',
        iconClass: 'el-icon-info'
      }).then(() => {
        router.push('/authentication')
      }).catch(() => {
        router.push('/MediumTerm')
      });
    }
  }
  // console.log('ddd')
  return foo
}