import { reqGetToekn, reqkey } from "@/api/public";
import * as types from "@/store/action-types";
import store from '@/store'
import Vue from 'vue'
function getKey() {
    return new Promise((resolve, reject) => {
        reqkey().then((res) => {
            if (res.code == 0) {
                let { servicePublicKey, clientPrivateKey, ifOpen, ignoreList } = res.data
                window.servicePublicKey = servicePublicKey
                window.clientPrivateKey = clientPrivateKey
                window.ifOpen = ifOpen
                window.ignoreList = ignoreList
                resolve()
            }
        })
    })
}
function getToken() {
    return new Promise((resolve, reject) => {
        let userId = Vue.ls.get("userId")
        let data = userId ? {
            "grant_type": "refresh_token",
            "client_id": "xjcec",
            "client_secret": "xjcec_secret",
            "scope": "all",
            "clientType": "trade_front",
            "refresh_token": Vue.ls.get("token")
        } : {
            "grant_type": "init",
            "client_id": "xjcec",
            "client_secret": "xjcec_secret",
            "scope": "all"
        }
        reqGetToekn(data).then((res) => {
            if (res.code == 0) {
                Vue.ls.set(types.ACCESS_TOKEN, res.data.accessToken);
                Vue.ls.set(types.REFRESH_TOKEN, res.data.refreshToken);
                Vue.ls.set(types.checkUkeyWhite, res.data.checkUkeyWhite);
                Vue.ls.set(types.EXPIRES_IN, res.data.expiresTime + "000");
                store.state.user.token = res.data.accessToken
                window.accessToken = res.data.accessToken
                resolve()
            }
        }).catch((err)=>{
            console.log(err)
        })
    })
}

function init() {
    return new Promise(async(resolve, reject)=>{
        await getKey()
        await getToken()
        resolve()
    })
}
export default init