<template>
  <div>
    <el-dialog
      title="支付"
      :before-close="handleClose"
      :visible.sync="visible"
      width="500px"
      center
    >
      <div class="all">
        <div class="pice_item">
          <div class="item_title">需支付金额</div>
          <div class="item_input">
            <input
              type="text"
              placeholder="请输入需支付金额"
              v-model="paymentamount"
            />
          </div>
        </div>
        <div class="pice_item" style="margin-top: 20px">
          <div class="item_title">支付方式</div>
          <!-- <div>{{ yue }}元</div> -->
          <div>
            <el-radio
              style="margin-top: 10px"
              v-model="radio"
              :label="item.bankType"
              border
              v-for="(item, index) in balance"
              :key="index"
            >
              <span v-if="item.bankType == 0">中信账户余额</span>
              <span v-if="item.bankType == 1">中金账户余额</span> (￥{{
                item.balance
              }})</el-radio
            >
            <!-- <el-radio  v-model="radio" label="1" border>中金账户余额(￥0)</el-radio> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisibleClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="visiblepay"
      width="500px"
      :before-close="handleClose"
      center
    >
      <div class="content">
        <PassWordInput
          ref="PassWordInput"
          @inputFinish="inputFinish"
          class="password-box"
        ></PassWordInput>
        <p v-if="tip" style="color: red">{{ tip }}</p>
        <el-button @click="submit" :disabled="istrue">确定</el-button>
      </div>
    </el-dialog>
    <InputPasswordDialog1
      ref=""
      :isShow="passWordIsShow"
      title="请设置交易密码"
      @close="closeDialog('passWordIsShow')"
      @submit="getPassword"
    ></InputPasswordDialog1>

    <InputPasswordDialog2
      :isShow="passWordIsShow1"
      title="请再次输入交易密码"
      @close="closeDialog('passWordIsShow1')"
      @submit="getPassword1"
    ></InputPasswordDialog2>
  </div>
</template>

<script>
import md5 from "js-md5";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";

import Vue from "vue";
import * as types from "@/store/action-types";
import { queryyue, havePwd, setPwd } from "@/api/home";
export default {
  data() {
    return {
      passWordIsShow: false,
      passWordIsShow1: false,
      yue: "0",
      password: "",
      tip: "",
      istrue: false,
      visiblepay: false,
      visible: this.isShow,
      paymentamount: this.row.orderPrice,
      radio: null,
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: {},
    },
    title: {
      type: String,
      default: "输入密码",
    },
    balance: {
      type: Array,
    },
  },
  watch: {
    isShow() {
      this.visible = this.isShow;
      this.queryYe();
    },
    row() {
      this.paymentamount = this.row.orderPrice;
      this.serviceGoodId = this.row.serviceGoodId;
    },
  },
  components: {
    PassWordInput: () => import("./PassWordInput.vue"),
    InputPasswordDialog1: () => import("./InputPasswordDialog.vue"),
    InputPasswordDialog2: () => import("./InputPasswordDialog2.vue"),
  },
  mounted() {
    // this.getbalance();
  },
  methods: {
    // 再次获取密码
    getPassword1(num) {
      this.pwd2 = md5(num);
      console.log(this.pwd, this.pwd2);
      if (num.length == 6) {
        // this.password.push(md5(num));
        // console.log(this.password);
        // 判断两次输入的值一样不
        if (this.pwd != this.pwd2) {
          this.password = [];
          this.passWordIsShow1 = false;
          this.$notify.error({
            title: "错误",
            message: "两次密码不一致,请重新输入",
            position: "bottom-right",
          });
        } else {
          this.passWordIsShow1 = false;
          let config = {
            pwd: this.pwd,
            rePwd: this.pwd2,
          };
          setPwd(config).then((res) => {
            console.log(res);
            this.password = [];
            this.$notify.success({
              title: "成功",
              message: "设置密码成功",
              position: "bottom-right",
            });
          });
        }
      }
    },
    getPassword(num) {
      this.pwd = md5(num);
      this.passWordIsShow = false;
      this.passWordIsShow1 = true;
    },
    closeDialog(tag) {
      this[tag] = false;
    },
    queryYe() {
      queryyue().then((res) => {
        if (res.code == 0) {
          // this.ye
          this.yue = res.data;
        }
      });
    },

    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.clones();
      } else {
        alert(msg);
        return false;
      }
    },
    clones() {
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      havePwd({
        enterpriseId,
      }).then((res) => {
        if (res.code == 0 && res.data) {
          this.visiblepay = true;
        } else if (res.code == 0 && !res.data) {
          this.$confirm("您还没有设置交易密码?", "提示", {
            confirmButtonText: "去设置",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              // this.$message({
              //   type: "success",
              //   message: "删除成功!",
              // });
              this.passWordIsShow = true;
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    centerDialogVisibleClick() {
      if(this.radio==null){
         this.$message.error('请选择支付方式');
         return false;
      }
      let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
      if (checkUkeyWhite == 0) {
        ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
      } else {
        this.clones();
      }
    },
    clearPassword() {
      if (this.$refs.PassWordInput) {
        this.$refs.PassWordInput.msg = "";
      }
    },
    handleClose() {
      this.$emit("update:isShow", false);
      this.clearPassword();
      this.visiblepay = false;
    },
    inputFinish(e) {
      this.password = e;
    },
    submit() {
      if (this.password.length < 6) {
        this.tip = "请输入密码";
        return;
      }
      console.log(this.password);
      this.handleClose();
      let obj = {
          bankType: this.radio,
        serviceGoodId: this.$route.query.serviceGoodId,
        payAmount: this.paymentamount,
        payPassword: md5(this.password),
      };
      this.$emit("submit", obj);
      this.istrue = true;
      setTimeout(() => {
        this.istrue = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.all {
  margin: 0 auto;
  text-align: center;
  .pice_item {
    display: flex;
    align-items: center;
    .item_input {
      input {
        border-bottom: 1px solid #ccc;
      }
    }
    .item_title {
      width: 200px;
    }
  }
}
.content {
  text-align: center;
  .password-box {
  }
  .el-button {
    margin-top: 20px;
  }
}
</style>