export const SET_USERINFO = 'SET_USERINFO'

export const GET_TOKEN = 'GET_TOKEN'

export const ACCESS_TOKEN = 'token'

export const REFRESH_TOKEN = 'refresh_token'

export const checkUkeyWhite = 'checkUkeyWhite'
export const GET_USERINFO = 'GET_USERINFO'

export const USER_INFO = 'user_info'

export const USER_NAME = 'userName'

export const EXPIRES_IN = 'expires_in'

export const TRADE_TOKEN = 'TRADE_TOKEN'

export const userId = 'userId'

export const telephone = 'telephone'

export const status = 'status'

export const active = 'active'

export const whetherAuth = 'whetherAuth'


export const ItemOn = 'ItemOn'
export const SELEST = 'SELEST'
export const IF_OPEN = 'ifopen'
export const ignoreList = 'ignoreList'
export const BASE_URL = "https://api.xjcec.com/"

export const publicKey = 'publicKey'
export const privateKey = 'privateKey'

// 用户登陆后是否开启服务大厅
export const IF_OPEN_FWDT = 'ifOpenFwdt'
