import url from './url/hy_url.js'
import http from '@/utils/request'
// 上传图片
export const reqUploadfile = (data) => {
  var formData = new FormData()
  formData.append('file', data)
  return http.post(url.upLoadUrl,formData)
}
// 识别接口
export const discernUploadfile = (data) => {
  var formData = new FormData()
  formData.append('file', data)
  return http.post('/third/third/ocrBusiness',formData)
}
export const ocrIdcardUploadfile = (data) => {
  var formData = new FormData()
  formData.append('file', data)
  return http.post('/third/third/ocrIdcard',formData)
}
// 获取用户信息
export const reqCompanyAuthDetail = (data) => http.get(url.getCompanyAuthDetail,
  {
    params:{
      ...data
    }
  }
)
// 企业认证
export const reqAddAuth = (data) => http.post(url.addAuthCompany,data)

export const updateConpanyBankAccount = (data) => http.post(url.updateConpanyBankAccount,data)
// 获取企业信息
export const reqCompanyDetail = (data) => http.get(url.getCompanyDetail,
  {
    params:{
      ...data
    }
  }
)
// 添加用户
export const reqAddUser = (data) => http.post(url.addUser,data)
// 获取用户列表
export const reqUserList = (data) => http.post(url.getList,data)
// 用户删除/启用
export const reqEnableOrDeleteUser = (data) => http.post(url.enableOrDeleteUser,data)
// 查询用户信息
export const reqUserDetail = (data) => http.get(url.getUserDetail,
  {
    params:{
      ...data
    }
  }
)
// 编辑用户
export const reqEditUser = (data) => http.post(url.editUser,data)
// 查询用户菜单权限
export const reqUserMenuTree = (data) => http.request({
  method: 'post',
  url: url.getUserMenuTree,
  params: data,
})
// 编辑用户权限
export const reqEditUserMenuTree = (data) => http.post(url.editUserMenuTree,data)
// 编辑用户权限
export const reqDictType = (data) => http.post(url.getDictType,data)
// 设置用户接收短信类型
export const financeChangeNotice = (data) => http.post(url.financeChangeNotice,data)
// 获取短信接受类型状态
export const getNoticeStatus = (data)=>http.post(url.getNoticeStatus,data)
// 获取列表
export const reqGoodsList = (data) => http.post(url.getGoodsList,data)
// 新增商品
export const reqAddGoods = (data) => http.post(url.addGoods,data)
// 新增竞价商品
export const reqAddBiddingGoods = (data) => http.post(url.addBiddingGoods,data)
// 关注
export const reqFollow = (data) => http.request({
  method: 'put',
  url: url.follow,
  params: data,
})
// 下单
export const reqCreateOrder = (data) => http.request({
  method: 'post',
  url: url.createOrder,
  params: data,
})
// 立即报价
export const reqAddBiddingOffer = (data) => http.post(url.biddingOffer,data)
// 发送验证码
export const reqSendCode = (data) => http.request({
  method: 'post',
  url: url.sendCode,
  params: data,
})
// 支付保证金
export const reqPayBond = (data) => http.post(url.payBond,data)
// 查询是否支付保证金
export const reqIsPayBond = (id) => http.get(url.isPayBond+id)
// 查询是否支付保证金
export const reqIsPayBonds = (id) => http.get(url.isPayBonds+`?enterpriseId=${id}`)
// 利润计算器
export const reqProfit = (data) => http.post(url.profitCalculation,data)
// 检测交易密码
export const reqCheckPassword = (data) => http.post(url.checkPassword,data)
// getTradeOffer
export const reqTradeOfferList = (data) => http.post(url.getTradeOffer,data)
// getSysDictDataByByDictType银行名称模糊搜索
export const getSysDictDataByByDictType = (data) => http.post(url.getSysDictDataByByDictType,data)

// 企业管理账号
export const getEnterpriseByEnterpriseId = (data) => http.get(url.getEnterpriseByEnterpriseId+`?enterpriseId=${data}`)
//修改企业管理账号
export const updateEnterpriseAccount = (data) => http.post(url.updateEnterpriseAccount,data)

export const registerAndAddWallet = (data) => http.post(url.registerAndAddWallet,data)
//注销账户
export const cancelBankAccount = (data) => http.post(url.cancelBankAccount,data)

export const detailMiningAuth = (data) => http.post('/system/sysMiningAuth/detailMiningAuth',data)


//拼团交易商品列表
export const groupTransactionList = (data) => http.post('/trade/tradeGroup/listGroup',data)

//拼团新增
export const reqAddGroupGoods = (data) => http.post(url.addGroupGoods,data)

// 参与拼团
export const joinGroup = (data) => http.post('/trade/tradeGroup/joinGroup',data)

//删除拼团
export const deteleGroup = (data) => http.post('/trade/tradeGroup/deleteGroup',data)

//铁路数据
export const railwayList = (data) => http.post('/system/sysLogisticsComputer/getStation',data)

//铁路计算值
export const railwayCalcd= (data) => http.post('/system/sysLogisticsComputer/getCalculateRail',data)
//公路计算值
export const highwayCalcd= (data) => http.post('/system/sysLogisticsComputer/getCalculateHigh',data)
// 线下支付保证金

export const bondOffline= (data) => http.post('/trade/tradeGoods/bidding/bondOffline',data)

// 参与竞拍
export const applyPartBidding = (data) => http.post('/trade/tradeGoods/applyPartBidding',data)
// 参与竞拍列表
export const listPartBidding = (data) => http.post('/trade/tradeGoods/listPartBidding',data)
// 参与竞拍审核
export const auditPartBidding = (data) => http.post('/trade/tradeGoods/auditPartBidding',data)





