import {
  reqGoodsList
} from "@/api/hy";
export default {
  state: {
    searchData: {
      page: 1,
      size: 9,
      searchName: "",
      goodsType: "",
      originPlace: "",
      releaseDateOrderBy: null,
      priceOrderBy: null,
      selectTradeType: "", //1:挂牌,2:竞价
      selectOrderType: "", //1:销售,2:采购
      endFlag: false, //列表是否需要已结束的订单
    },
    tableList: {
      list: [],
      total: 0,
    },
    select: 0
  },
  mutations: {
    permission(state, payload) {
      state.searchData.searchName = payload
    },
    changetableList(state, payload) {
      state.tableList.list = payload.records;
      state.tableList.total = payload.total;
    },
    pagechange(state, payload) {
      state.searchData.page = payload
    },
    selectchange(state, payload) {
      state.select = payload
    }
  },
  actions: {
    async searchList(commigt, data) {
      const typeArr = [{
          selectTradeType: 1,
          selectOrderType: 1,
        },
        {
          selectTradeType: 1,
          selectOrderType: 2,
        },
        {
          selectTradeType: 2,
          selectOrderType: 1,
        },
        {
          selectTradeType: 2,
          selectOrderType: 2,
        },
      ];
      let obj = {
        ...commigt.state.searchData,
        ...typeArr[commigt.state.select]
      }
      // console.log(commigt.commit());
      let res = await reqGoodsList(obj)
      console.log(res);
      let list = res.data.records;
      list.forEach((item) => {
        let a = [];
        if (item.quotas) {
          let quotas = item.quotas;
          quotas.forEach((items) => {
            a.push(
              items.name +
              items.unit.split(";")[2] +
              items.value +
              items.unit.split(";")[1]
            );
          });
        }
        item.title = a.toString();
      });
      commigt.state.tableList.list = list;
      if (res.code == 0) {
        commigt.commit('changetableList', res.data)
      }
      // let {data} = await reqGetUser()
      // Vue.ls.set(types.USER_INFO, data)
      // commit(types.GET_USERINFO, data)
    }
  },
}