var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.path !== '/login')?_c('div',{staticClass:"footer center"},[_c('div',{staticClass:"footer_list center"},[_c('div',{staticClass:"box boxone"},[(_vm.istrue)?_c('div',{staticStyle:{"margin-top":"56px"},on:{"click":_vm.TClick}},[_vm._m(0),_vm._m(1)]):_c('div',{staticStyle:{"margin-top":"56px"},on:{"click":_vm.TClick}},[_vm._m(2),_vm._m(3)]),_vm._m(4)])])]):_c('div',{staticClass:"footers"},[_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/home_img/7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"28px"}},[_c('div',{staticClass:"shou"},[_vm._v("工信部备案：新ICP备17000837号-1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/home_img/7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"28px"}},[_c('span',{staticClass:"shou"},[_vm._v("工信部备案：新ICP备17000837号-1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom_img",staticStyle:{"margin-top":"51px"}},[_c('img',{attrs:{"src":require("../assets/home_img/6.png"),"alt":""}}),_c('div',{staticStyle:{"margin-top":"8px"}},[_vm._v("扫码关注我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footers_on"},[_c('div',[_vm._v("工信部备案：新ICP备17000837号-1")])])
}]

export { render, staticRenderFns }